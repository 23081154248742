<template>
  <div v-if="visible" class="ucsc-autocompleteDropdown">
    <div class="ucsc-autocompleteDropdown__inner">
      <div v-if="$slots.header" class="ucsc-autocompleteDropdown__header">
        <slot name="header"></slot>
      </div>
      <ul
        v-else
        ref="ZonesList"
        class="ucsc-autocompleteDropdown__menu"
        @mouseover="$emit('mouseover')"
        @mouseleave="$emit('mouseleave')"
      >
        <li
          v-for="(item, i) in items"
          ref="ZoneElements"
          :key="i"
          :class="[
            'ucsc-autocompleteDropdown__item text-black-light',
            {
              'ucsc-autocompleteDropdown__item--selected':
                item.selected && i !== hoveredZoneIndex,
              'bg-blue text-white': i === hoveredZoneIndex,
            },
          ]"
          @click="onItemClick(item)"
        >
          <span>{{ item[field] }}</span>
          <span
            :class="[
              'ucsc-autocompleteDropdown__item-check border-black-light',
              {
                'after:opacity-0': !item.selected,
                'after:opacity-100': item.selected,
                'border-white': i === hoveredZoneIndex,
                'after:border-blue': item.selected && i !== hoveredZoneIndex,
                'after:border-white': item.selected && i === hoveredZoneIndex,
              },
            ]"
          ></span>
        </li>
      </ul>
      <div v-if="$slots.footer" class="ucsc-autocompleteDropdown__footer">
        <slot name="footer"></slot>
        <OButton variant="secondary" @click="$emit('dropdown-cancel')"
          >Annulla</OButton
        >
        <OButton variant="primary" @click="$emit('dropdown-submit')"
          >Conferma</OButton
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array,
      default: () => {
        return []
      },
    },

    hoveredZoneIndex: {
      type: Number,
      default: 0,
    },

    field: {
      type: String,
      default: 'name',
    },
  },
  watch: {
    hoveredZoneIndex(newIndex, oldIndex) {
      if (!this.$refs.ZoneElements) return
      // On change handles the scrolling of the zone list
      // If no element is found either the index is -1 or
      // the list is not yet ready.
      const hoveredZoneEl = this.$refs.ZoneElements[newIndex]

      if (!hoveredZoneEl) return

      const zonesListEl = this.$refs.ZonesList

      // Checks if element is in viewport
      // upper bound is the element offsetTop (element at precisely the top)
      // lower bound is element top + list height - element height (element precisely at the bottom)
      const isElementInViewport =
        hoveredZoneEl.offsetTop >= zonesListEl.scrollTop &&
        hoveredZoneEl.offsetTop <=
          zonesListEl.scrollTop +
            zonesListEl.clientHeight -
            hoveredZoneEl.clientHeight

      if (isElementInViewport) return

      // Calculates with the previous logic the snapping point
      // for the current element and then decides which one to use
      // the top snapping when going up (oldIndex > newIndex)
      // the bottom snapping when going down
      const topSnapping = hoveredZoneEl.offsetTop
      const bottomSnapping =
        hoveredZoneEl.offsetTop +
        hoveredZoneEl.clientHeight -
        zonesListEl.clientHeight
      const top = oldIndex > newIndex ? topSnapping : bottomSnapping
      zonesListEl.scrollTo({
        top,
        behaviour: 'smooth',
      })
    },
  },

  methods: {
    onItemClick(item) {
      item.selected = !item.selected
      this.$emit('item-select', item)
    },
  },
}
</script>
