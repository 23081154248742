<template>
  <div v-click-outside="() => (open = false)" class="ucsc-dropdown-range">
    <ValidationProvider
      :vid="vid"
      :name="$attrs.name"
      :slim="true"
      :rules="rules"
    >
      <OField
        :label="label"
        :variant="errors && errors[$attrs.name] ? 'danger' : ''"
        :message="errors && errors[$attrs.name] && errors[$attrs.name][0]"
        :root-class="fieldClasses"
        :data-name="$attrs.name"
      >
        <div
          class="ucsc-dropdown-range__toggle"
          :class="{
            'ucsc-dropdown-range__toggle--danger':
              errors && errors[$attrs.name] && errors[$attrs.name][0],
            'ucsc-dropdown-range__toggle--open': open,
            'ucsc-dropdown-range__toggle--open-spaced': open && spaced,
            'ucsc-dropdown-range__toggle--empty': !computedValue,
          }"
          tabindex="0"
          @keyup.enter="onToggle"
          @click="onToggle"
        >
          <span
            v-dompurify-html="text || $attrs.placeholder"
            class="ucsc-dropdown-range__toggle-text"
            :class="{
              'ucsc-dropdown-range__toggle-text--empty':
                !computedValue.from && !computedValue.to,
            }"
          >
          </span>
          <SvgIcon
            :name="open ? 'angle-up-blue' : 'angle-down'"
            class="ucsc-dropdown-range__toggle-icon"
          />
        </div>
        <div
          v-show="open"
          :class="[
            'ucsc-dropdown-range__menu',
            {
              'ucsc-dropdown-range__menu--spaced': spaced,
            },
            dropdownClasses,
          ]"
        >
          <div class="flex justify-between gap-4">
            <OField
              :label="labels ? 'Da' : null"
              :class="[
                'w-full',
                {
                  'text-gray': focusOn !== 'from',
                },
                inputClasses,
              ]"
            >
              <input
                ref="from"
                :value="computedValue.from | toLocaleString"
                name="from"
                inputmode="numeric"
                class="ucsc-input ucsc-input--focus"
                :class="{
                  'ucsc-input--in-range': symbol,
                  'ucsc-input--placeholder': focusOn !== 'from',
                }"
                :min="min"
                :max="max"
                :placeholder="placeholders ? 'Da' : null"
                @input="onInput($event, 'from')"
                @focus="onFocus('from')"
                @blur="onBlur"
                @keypress="onKeypress"
              />
              <span
                v-if="symbol"
                v-dompurify-html="symbol"
                class="ucsc-dropdown-range__input-symbol"
              >
              </span>
            </OField>
            <OField
              :label="labels ? 'A' : null"
              :class="[
                'w-full',
                {
                  'text-gray': focusOn !== 'to',
                },
                inputClasses,
              ]"
            >
              <input
                ref="to"
                :value="computedValue.to | toLocaleString"
                name="to"
                inputmode="numeric"
                class="ucsc-input ucsc-input--focus"
                :class="{
                  'ucsc-input--in-range': symbol,
                  'ucsc-input--placeholder': focusOn !== 'to',
                }"
                :min="min"
                :max="max"
                :placeholder="placeholders ? 'A' : null"
                @input="onInput($event, 'to')"
                @focus="onFocus('to')"
                @blur="onBlur"
                @keypress="onKeypress"
              />
              <span
                v-if="symbol"
                v-dompurify-html="symbol"
                class="ucsc-dropdown-range__input-symbol"
              >
              </span>
            </OField>
          </div>
        </div>
      </OField>
    </ValidationProvider>
  </div>
</template>

<script>
// Libs
import isNil from 'lodash/isNil'

export default {
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      default: () => null,
    },

    vid: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: '',
    },

    fieldClasses: {
      type: String,
      default: '',
    },

    dropdownClasses: {
      type: String,
      default: '',
    },

    inputClasses: {
      type: String,
      default: '',
    },

    symbol: {
      type: String,
      default: '',
    },

    rules: {
      type: [Object, String],
      default: '',
    },

    errors: {
      type: Object,
      default: () => {
        return null
      },
    },

    min: {
      type: Number,
      default: null,
    },

    max: {
      type: Number,
      default: null,
    },

    labels: {
      type: Boolean,
      default: false,
    },

    placeholders: {
      type: Boolean,
      default: false,
    },

    spaced: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      open: false,
      focusOn: '',
    }
  },

  computed: {
    computedValue() {
      return this.value ? this.value : {}
    },

    text() {
      const { from, to } = this.computedValue

      if (!from && !to) {
        return
      }

      const fromLocale = this.$options.filters.toLocaleString(from)
      const toLocale = this.$options.filters.toLocaleString(to)

      let text = this.$attrs.placeholder

      const symbol = this.symbol ? ` ${this.symbol}` : ''

      switch (true) {
        case Boolean(from && to) && from === to:
          text = `Esattamente ${fromLocale + symbol}`
          break

        case Boolean(from && to):
          text = `Da ${fromLocale + symbol} - A ${toLocale + symbol}`
          break

        case Boolean(from && !to):
          text = `A partire da ${fromLocale + symbol}`
          break

        case Boolean(!from && to):
          text = `Fino a ${toLocale + symbol}`
      }

      return text
    },
  },

  watch: {
    text: {
      immediate: true,
      handler(text) {
        this.$emit('update-text', text)
      },
    },

    open(open) {
      if (open) {
        return
      }

      this.$emit('closed')
    },
  },

  methods: {
    update(key, value) {
      this.$emit('input', { ...this.computedValue, [key]: value })
    },

    onToggle() {
      this.open = !this.open

      if (this.open) {
        setTimeout(() => {
          this.$refs.from.focus()
        }, 10)
      }
    },

    onFocus(input) {
      this.focusOn = input
    },

    onBlur() {
      this.focusOn = ''
      this.reverseFromTo()

      // Input completed.
      setTimeout(() => {
        if (this.focusOn) {
          return
        }

        this.open = false
      }, 100)
    },

    onInput(event, field) {
      const eventValue = event.target.value
      const value = eventValue ? parseInt(eventValue.replaceAll('.', '')) : null

      switch (true) {
        case !isNil(value) && !isNil(this.min) && value < this.min:
        case !isNil(value) && !isNil(this.max) && value > this.max:
          this.update(field, this.computedValue[field])
          break

        default:
          this.update(field, value)
      }
    },

    onKeypress(event) {
      const { key, keyCode } = event

      if (key === 'Enter') {
        this.open = false
        return
      }

      return keyCode < 48 || keyCode > 57
    },

    reverseFromTo() {
      const { from, to } = this.computedValue

      if (!from || !to || from < to) {
        return
      }

      this.computedValue.from = to
      this.computedValue.to = from
    },
  },
}
</script>

<style lang="postcss">
@import '~/assets/css/components/dropdown-range';
@import '~/assets/css/components/field';
</style>
