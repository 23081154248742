var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_vm.open = false)),expression:"() => (open = false)"}],staticClass:"ucsc-dropdown-range"},[_c('ValidationProvider',{attrs:{"vid":_vm.vid,"name":_vm.$attrs.name,"slim":true,"rules":_vm.rules}},[_c('OField',{attrs:{"label":_vm.label,"variant":_vm.errors && _vm.errors[_vm.$attrs.name] ? 'danger' : '',"message":_vm.errors && _vm.errors[_vm.$attrs.name] && _vm.errors[_vm.$attrs.name][0],"root-class":_vm.fieldClasses,"data-name":_vm.$attrs.name}},[_c('div',{staticClass:"ucsc-dropdown-range__toggle",class:{
          'ucsc-dropdown-range__toggle--danger':
            _vm.errors && _vm.errors[_vm.$attrs.name] && _vm.errors[_vm.$attrs.name][0],
          'ucsc-dropdown-range__toggle--open': _vm.open,
          'ucsc-dropdown-range__toggle--open-spaced': _vm.open && _vm.spaced,
          'ucsc-dropdown-range__toggle--empty': !_vm.computedValue,
        },attrs:{"tabindex":"0"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onToggle.apply(null, arguments)},"click":_vm.onToggle}},[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.text || _vm.$attrs.placeholder),expression:"text || $attrs.placeholder"}],staticClass:"ucsc-dropdown-range__toggle-text",class:{
            'ucsc-dropdown-range__toggle-text--empty':
              !_vm.computedValue.from && !_vm.computedValue.to,
          }}),_vm._v(" "),_c('SvgIcon',{staticClass:"ucsc-dropdown-range__toggle-icon",attrs:{"name":_vm.open ? 'angle-up-blue' : 'angle-down'}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],class:[
          'ucsc-dropdown-range__menu',
          {
            'ucsc-dropdown-range__menu--spaced': _vm.spaced,
          },
          _vm.dropdownClasses,
        ]},[_c('div',{staticClass:"flex justify-between gap-4"},[_c('OField',{class:[
              'w-full',
              {
                'text-gray': _vm.focusOn !== 'from',
              },
              _vm.inputClasses,
            ],attrs:{"label":_vm.labels ? 'Da' : null}},[_c('input',{ref:"from",staticClass:"ucsc-input ucsc-input--focus",class:{
                'ucsc-input--in-range': _vm.symbol,
                'ucsc-input--placeholder': _vm.focusOn !== 'from',
              },attrs:{"name":"from","inputmode":"numeric","min":_vm.min,"max":_vm.max,"placeholder":_vm.placeholders ? 'Da' : null},domProps:{"value":_vm._f("toLocaleString")(_vm.computedValue.from)},on:{"input":function($event){return _vm.onInput($event, 'from')},"focus":function($event){return _vm.onFocus('from')},"blur":_vm.onBlur,"keypress":_vm.onKeypress}}),_vm._v(" "),(_vm.symbol)?_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.symbol),expression:"symbol"}],staticClass:"ucsc-dropdown-range__input-symbol"}):_vm._e()]),_vm._v(" "),_c('OField',{class:[
              'w-full',
              {
                'text-gray': _vm.focusOn !== 'to',
              },
              _vm.inputClasses,
            ],attrs:{"label":_vm.labels ? 'A' : null}},[_c('input',{ref:"to",staticClass:"ucsc-input ucsc-input--focus",class:{
                'ucsc-input--in-range': _vm.symbol,
                'ucsc-input--placeholder': _vm.focusOn !== 'to',
              },attrs:{"name":"to","inputmode":"numeric","min":_vm.min,"max":_vm.max,"placeholder":_vm.placeholders ? 'A' : null},domProps:{"value":_vm._f("toLocaleString")(_vm.computedValue.to)},on:{"input":function($event){return _vm.onInput($event, 'to')},"focus":function($event){return _vm.onFocus('to')},"blur":_vm.onBlur,"keypress":_vm.onKeypress}}),_vm._v(" "),(_vm.symbol)?_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.symbol),expression:"symbol"}],staticClass:"ucsc-dropdown-range__input-symbol"}):_vm._e()])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }